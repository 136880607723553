import styled from 'styled-components';
import { Chip } from '@material-ui/core';

const StyledChip = styled(Chip).attrs((props) => ({
  variant: props.selected ? 'default' : 'outlined',
}))`
  && {
    margin: 2px;
    width: 60px;
    ${(props) => (props.selected ? `border: 1px solid black;` : ``)}
  }
`;

export default StyledChip;
